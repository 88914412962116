
import BMessage from 'buetify/lib/components/message/BMessage';
import { defineComponent } from 'vue';
import UserIcon from '../../../../../components/icons/UserIcon';

export default defineComponent({
	name: 'message-icons-example',
	components: {
		BMessage
	},
	setup() {
		return {
			UserIcon
		};
	}
});
