
import BMessage from 'buetify/lib/components/message/BMessage';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'message-headerless-example',
	components: {
		BMessage
	}
});
